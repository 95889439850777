// src/components/Explore.js
import React from 'react';
import './Explore.css';

import omame1 from '../assets/omame.jpg'
import omame2 from '../assets/omame-2.jpg'
import omame3 from '../assets/omame-3.jpg'
import omame4 from '../assets/omame-4.jpg'
import omame5 from '../assets/omame-5.jpg'
import omame6 from '../assets/omame-6.jpg'
import omame7 from '../assets/omame-7.jpg'
import omame8 from '../assets/omame-8.jpg'

const Explore = () => {
  return (
    <section className="explore-section">

      <div className="row">
        <div className="column">
          <img src={omame1} style={{ width: '100%' }} />
          <img src={omame2} style={{ width: '100%' }} />
        </div>
        <div className="column">
          <img src={omame3} style={{ width: '100%' }} />
          <img src={omame4} style={{ width: '100%' }} />
        </div>
        <div className="column">
          <img src={omame5} style={{ width: '100%' }} />
          <img src={omame6} style={{ width: '100%' }} />
        </div>
        <div className="column">
          <img src={omame7} style={{ width: '100%' }} />
          <img src={omame8} style={{ width: '100%' }} />
        </div>
      </div>

    </section>
  );
};

export default Explore;