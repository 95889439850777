// src/components/Linked.js
import React from 'react';
import './Linked.css';

const Linked = () => {
  return (
    <section id="about" className="about-section">
      <h1>About Omame The HairCat</h1>
      <p>OMAME is a viral Japanese <span className='highlight-bg'>HAIRCAT</span>. </p>
      <p><span className='highlight-bg'>Satoshi</span> may or may not be Japanese but </p>
      <p><span className='highlight-bg'>Omame</span> definitely is.</p>
      <a href='https://pump.fun/########' rel="noreferrer" target='_blank'><button className="buy-button">༼ つ ◕_◕ ༽つ HAIRCAT</button></a>
      <div className='linked'>
      {/* <a href='https://raydium.io/swap/?outputMint=#&inputMint=sol' rel="noreferrer" target='_blank'><button className="buy-button">Take Some 😻</button></a>
      <div className='linked'> */}
      <a href='https://x.com/omame_haircat' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-square-x-twitter"></i></button></a>
      <a href='https://www.instagram.com/cici.toto.mametchi/' rel="noreferrer" target='_blank'><button className="link-button"><i class="fab fa-instagram"></i></button></a>
        
      <a href='https://pump.fun/#######' rel="noreferrer" target='_blank'><button className="link-button"><i class="fa-solid fa-chart-line"></i></button></a>
      </div>
    </section>
  );
};

export default Linked;